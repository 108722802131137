<template>
  <div v-if="deckPlan" data-cy="boat-deck-plan">
    <h2>Deck plan</h2>
    <Imagery
      :lazy-src="deckPlan.src"
      :lazy-srcset="deckPlan.srcSet"
      :alt="deckPlan.alt"
      data-cy="boat-deck-plan__imagery"
    ></Imagery>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Imagery from "atlas/src/components/Imagery/Imagery.vue";
import Image from "~/lib/types/Image";

export default Vue.extend({
  name: "BoatDeckPlan",
  components: { Imagery },
  props: {
    deckPlan: {
      required: false,
      type: Object as PropType<Image>,
      default: undefined,
    },
  },
});
</script>
