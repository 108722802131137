<template>
  <Page v-bind="page">
    <ErrorBoundary v-if="gallery?.images.length">
      <Gallery
        v-bind="gallery"
        id="gallery"
        data-anchor-text="Gallery"
        class="
          l-container
          u-padding-top--0-5
          u-margin-bottom--4
          md:u-padding-top--2 md:u-margin-bottom--6
        "
      />
    </ErrorBoundary>
    <ErrorBoundary>
      <SubNavigation />
    </ErrorBoundary>
    <ErrorBoundary
      v-if="introduction"
      class="
        l-container l-container--small
        u-margin-top--2
        u-margin-bottom--4
        sm:u-margin-top--6
      "
    >
      <Introduction
        id="summary"
        data-anchor-text="Summary"
        v-bind="introduction"
      />
    </ErrorBoundary>
    <div class="l-container">
      <ErrorBoundary
        v-if="boatVideo"
        id="video"
        class="u-margin-top--4 sm:u-margin-top--6"
        data-anchor-text="Video"
      >
        <BoatVideo v-bind="boatVideo" />
      </ErrorBoundary>
      <ErrorBoundary>
        <BoatFeatures
          v-if="boatFeatures"
          id="features"
          data-anchor-text="Features"
          v-bind="boatFeatures"
          data-cy="boat-page__features"
          class="u-margin-top--4 sm:u-margin-top--6"
        />
      </ErrorBoundary>
      <ErrorBoundary v-if="roomOptions && roomOptions.length">
        <BoatCabinList
          id="cabins"
          :roomOptions="roomOptions"
          data-anchor-text="Cabins"
          class="u-margin-top--4 sm:u-margin-top--6"
        />
      </ErrorBoundary>
      <ErrorBoundary v-if="deckPlan">
        <BoatDeckPlan
          id="deck-plan"
          class="u-margin-top--4 sm:u-margin-top--6"
          :deckPlan="deckPlan"
          data-anchor-text="Deck plan"
        />
      </ErrorBoundary>
      <ErrorBoundary v-if="boatTrips && boatTrips?.trips?.length > 0">
        <TripList
          id="trips"
          type="utility"
          data-anchor-text="Trips"
          v-bind="boatTrips"
          class="u-margin-top--4 sm:u-margin-top--6"
        />
      </ErrorBoundary>
    </div>
  </Page>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { BoatPageProps } from "./Props";
import BoatDeckPlan from "~~/components/BoatDeckPlan/BoatDeckPlan.vue";
import BoatFeatures from "~~/components/BoatFeatures/BoatFeatures.vue";
import Introduction from "~~/components/Introduction/Introduction.vue";
import Page from "~~/components/Page/Page.vue";
import SubNavigation from "~~/components/SubNavigation/SubNavigation.vue";
import ErrorBoundary from "~~/components/ErrorBoundary/ErrorBoundary.vue";
import BoatCabinList from "~~/components/BoatCabinList/BoatCabinList.vue";
import Gallery from "~~/components/Gallery/Gallery.vue";
import TripList from "~/components/TripList/TripList.vue";
import BoatVideo from "~/components/BoatVideo/BoatVideo.vue";

export default Vue.extend({
  name: "BoatPage",
  components: {
    Page,
    Introduction,
    SubNavigation,
    ErrorBoundary,
    BoatFeatures,
    BoatCabinList,
    Gallery,
    BoatDeckPlan,
    TripList,
    BoatVideo,
  },
  props: {
    page: {
      type: Object as PropType<BoatPageProps["page"]>,
      required: true,
    },
    introduction: {
      type: Object as PropType<BoatPageProps["introduction"]>,
      required: false,
      default: undefined,
    },
    boatFeatures: {
      type: Object as PropType<BoatPageProps["boatFeatures"]>,
      required: false,
      default: undefined,
    },
    roomOptions: {
      type: Array as PropType<BoatPageProps["roomOptions"]>,
      required: false,
      default: undefined,
    },
    gallery: {
      type: Object as PropType<BoatPageProps["gallery"]>,
      required: false,
      default: undefined,
    },
    boatTrips: {
      type: Object as PropType<BoatPageProps["boatTrips"]>,
      required: false,
      default: undefined,
    },
    deckPlan: {
      required: false,
      type: Object as PropType<BoatPageProps["deckPlan"]>,
      default: undefined,
    },
    boatVideo: {
      required: false,
      type: Object as PropType<BoatPageProps["boatVideo"]>,
      default: undefined,
    },
  },
});
</script>
