<template>
  <div data-cy="boat-section">
    <h2 data-cy="boat-section__display-name">{{ title }}</h2>
    <p v-if="description" data-cy="boat-section__description">
      {{ description }}
    </p>
    <BoatList :boats="boats" data-cy="boat-section__boat-list" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { BoatSectionProps } from "./Props";
import BoatList from "~/components/BoatList/BoatList.vue";
export default Vue.extend({
  name: "BoatSection",
  components: {
    BoatList,
  },
  props: {
    title: {
      type: String as PropType<BoatSectionProps["title"]>,
      required: true,
    },
    description: {
      type: String as PropType<BoatSectionProps["description"]>,
      required: false,
      default: null,
    },
    boats: {
      type: Array as PropType<BoatSectionProps["boats"]>,
      required: true,
    },
  },
});
</script>
