<template>
  <AccordionItem>
    <b slot="title">Technical specifications</b>
    <div slot="content">
      <div v-if="length" data-cy="boat-technical-specification___length">
        <span class="u-font-weight--semi-bold">Length: </span>{{ length }}
      </div>
      <div v-if="width" data-cy="boat-technical-specification___width">
        <span class="u-font-weight--semi-bold">Width: </span>{{ width }}
      </div>

      <div v-if="decks" data-cy="boat-technical-specification___decks">
        <span class="u-font-weight--semi-bold">Decks: </span>{{ decks }}
      </div>
      <div
        v-if="yearBuilt"
        data-cy="boat-technical-specification___year-built-in"
      >
        <span class="u-font-weight--semi-bold">Year Built: </span
        >{{ yearBuilt }}
      </div>
      <div v-if="builtIn" data-cy="boat-technical-specification___built-in">
        <span class="u-font-weight--semi-bold">Built In: </span>{{ builtIn }}
      </div>
      <div v-if="iceClass" data-cy="boat-technical-specification___ice-class">
        <span class="u-font-weight--semi-bold">Ice Class: </span>{{ iceClass }}
      </div>
      <div v-if="speed" data-cy="boat-technical-specification___speed">
        <span class="u-font-weight--semi-bold">Speed: </span>{{ speed }}
      </div>
      <div
        v-if="cruiseSpeed"
        data-cy="boat-technical-specification___cruise-speed"
      >
        <span class="u-font-weight--semi-bold">CruiseSpeed: </span
        >{{ cruiseSpeed }}
      </div>
      <div
        v-if="electricity"
        data-cy="boat-technical-specification___electricity"
      >
        <span class="u-font-weight--semi-bold">Electricity: </span
        >{{ electricity }}
      </div>

      <div
        v-if="grossTonnage"
        data-cy="boat-technical-specification___gross-tonnage"
      >
        <span class="u-font-weight--semi-bold">Gross Tonnage: </span
        >{{ grossTonnage }}
      </div>
      <div v-if="lifeRafts" data-cy="boat-technical-specification___lifr-rafts">
        <span class="u-font-weight--semi-bold">Life Rafts: </span
        >{{ lifeRafts }}
      </div>
      <div v-if="zodiacs" data-cy="boat-technical-specification___zodiacs">
        <span class="u-font-weight--semi-bold">Zodiacs: </span>{{ zodiacs }}
      </div>
      <div v-if="safety" data-cy="boat-technical-specification___safety">
        <span class="u-font-weight--semi-bold">Safety: </span>{{ safety }}
      </div>
      <div v-if="other" data-cy="boat-technical-specification___other">
        <span class="u-font-weight--semi-bold">Other: </span>{{ other }}
      </div>
    </div>
  </AccordionItem>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import AccordionItem from "atlas/src/components/Accordion/AccordionItem.vue";

import { BoatTechnicalSpecificationsProps } from "./Props";

export default Vue.extend({
  name: "BoatTechnicalSpecifications",
  components: { AccordionItem },
  props: {
    length: {
      type: String as PropType<BoatTechnicalSpecificationsProps["length"]>,
      required: false,
      default: null,
    },
    width: {
      type: String as PropType<BoatTechnicalSpecificationsProps["width"]>,
      required: false,
      default: null,
    },
    decks: {
      type: String as PropType<BoatTechnicalSpecificationsProps["decks"]>,
      required: false,
      default: null,
    },
    electricity: {
      type: String as PropType<BoatTechnicalSpecificationsProps["electricity"]>,
      required: false,
      default: null,
    },
    yearBuilt: {
      type: String as PropType<BoatTechnicalSpecificationsProps["yearBuilt"]>,
      required: false,
      default: null,
    },
    builtIn: {
      type: String as PropType<BoatTechnicalSpecificationsProps["builtIn"]>,
      required: false,
      default: null,
    },
    iceClass: {
      type: String as PropType<BoatTechnicalSpecificationsProps["iceClass"]>,
      required: false,
      default: null,
    },
    speed: {
      type: String as PropType<BoatTechnicalSpecificationsProps["speed"]>,
      required: false,
      default: null,
    },
    cruiseSpeed: {
      type: String as PropType<BoatTechnicalSpecificationsProps["cruiseSpeed"]>,
      required: false,
      default: null,
    },
    zodiacs: {
      type: String as PropType<BoatTechnicalSpecificationsProps["zodiacs"]>,
      required: false,
      default: null,
    },
    grossTonnage: {
      type: String as PropType<
        BoatTechnicalSpecificationsProps["grossTonnage"]
      >,
      required: false,
      default: null,
    },
    lifeRafts: {
      type: String as PropType<BoatTechnicalSpecificationsProps["lifeRafts"]>,
      required: false,
      default: null,
    },
    safety: {
      type: String as PropType<BoatTechnicalSpecificationsProps["safety"]>,
      required: false,
      default: null,
    },
    other: {
      type: String as PropType<BoatTechnicalSpecificationsProps["other"]>,
      required: false,
      default: null,
    },
  },
});
</script>
