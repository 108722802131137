<template>
  <AccordionItem v-if="accessibilities">
    <b slot="title">Accessibility</b>
    <div slot="content">
      <div
        v-for="(accessibility, index) in accessibilities"
        :key="index"
        data-cy="boat-accessibilities"
      >
        {{ accessibility }}
      </div>
    </div>
  </AccordionItem>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import AccordionItem from "atlas/src/components/Accordion/AccordionItem.vue";

export default Vue.extend({
  name: "BoatAccessibility",
  components: { AccordionItem },
  props: {
    accessibilities: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
});
</script>
