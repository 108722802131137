<template>
  <Page v-bind="page">
    <ErrorBoundary
      v-if="introduction"
      class="
        l-container l-container--small
        u-margin-top--2
        u-margin-bottom--4
        sm:u-margin-top--6
      "
    >
      <Introduction
        id="overview"
        data-anchor-text="Overview"
        v-bind="introduction"
      />
    </ErrorBoundary>

    <ErrorBoundary>
      <SubNavigation />
    </ErrorBoundary>

    <ErrorBoundary v-if="hasPanels">
      <Panels
        v-bind="panels"
        class="l-container u-margin-top--4 sm:u-margin-top--6"
      />
    </ErrorBoundary>
  </Page>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { ClusterPageProps } from "./Props";
import Page from "~~/components/Page/Page.vue";
import Introduction from "~/components/Introduction/Introduction.vue";
import Panels from "~/components/Panels/Panels.vue";
import ErrorBoundary from "~~/components/ErrorBoundary/ErrorBoundary.vue";
import SubNavigation from "~/components/SubNavigation/SubNavigation.vue";

export default Vue.extend({
  name: "ClusterPage",
  components: { Page, Introduction, Panels, ErrorBoundary, SubNavigation },
  props: {
    page: {
      type: Object as PropType<ClusterPageProps["page"]>,
      required: true,
    },
    introduction: {
      type: Object as PropType<ClusterPageProps["introduction"]>,
      required: true,
    },
    panels: {
      type: Object as PropType<ClusterPageProps["panels"]>,
      required: true,
    },
  },
  computed: {
    hasPanels() {
      return this?.panels?.panels?.length;
    },
  },
});
</script>
