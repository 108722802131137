<template>
  <AccordionItem v-if="equipments">
    <b slot="title">Equipment</b>
    <div slot="content" data-cy="boat-equipments">
      <div v-for="(equipment, index) in equipments" :key="index">
        {{ equipment }}
      </div>
    </div>
  </AccordionItem>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import AccordionItem from "atlas/src/components/Accordion/AccordionItem.vue";

export default Vue.extend({
  name: "BoatEquipment",
  components: { AccordionItem },
  props: {
    equipments: {
      type: Array as PropType<string[]>,
      required: true,
      default: null,
    },
  },
});
</script>
