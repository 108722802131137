<template>
  <div data-cy="category-section">
    <h2 data-cy="category-section__display-name">{{ displayName }}</h2>
    <CategoryList v-if="categories.length > 0" :categories="categories" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { CategorySectionProps } from "./Props";
import CategoryList from "~/components/CategoryList/CategoryList.vue";

export default Vue.extend({
  name: "CategorySection",
  components: {
    CategoryList,
  },
  props: {
    displayName: {
      type: String as PropType<CategorySectionProps["displayName"]>,
      required: true,
    },
    categories: {
      type: Array as PropType<CategorySectionProps["categories"]>,
      required: true,
    },
  },
});
</script>
