<template>
  <div
    data-cy="carbon-calculation--empty-state"
    class="carbon-calculation carbon-calculation--empty-state"
  >
    <div
      class="
        carbon-calculation__content
        u-text-align--left
        md:u-text-align--center
      "
    >
      <div>
        We’re in the process of figuring out how much CO<sub>2</sub>-e this trip
        generates. In the meantime, find out more about
        <Link :href="$link.create('/climate')" target="_blank"
          >our climate commitments</Link
        >
        and
        <Link :href="$link.create('/carbon-management')" target="_blank"
          >carbon reduction target.
        </Link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Link from "../Link/Link.vue";

export default Vue.extend({
  name: "CarbonCalculationEmptyState",
  components: {
    Link,
  },
});
</script>
