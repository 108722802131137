<template>
  <BoatCardAtlas
    :boat-name="boatName"
    :description="description"
    :boat-type="boatType"
    :passengers="passengers"
    :deck-plan="deckPlan"
    :url="$link.create(url)"
    :image="image"
    data-cy="boat-card"
  />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import BoatCardAtlas from "atlas/src/components/BoatCard/BoatCard.vue";
import { BoatCardProps } from "./Props";

export default Vue.extend({
  name: "BoatCard",
  components: {
    BoatCardAtlas,
  },
  props: {
    image: {
      type: Object as PropType<BoatCardProps["image"]>,
      required: false,
      default: undefined,
    },
    boatName: {
      type: String as PropType<BoatCardProps["boatName"]>,
      required: true,
    },
    description: {
      type: String as PropType<BoatCardProps["description"]>,
      required: false,
      default: null,
    },
    boatType: {
      type: String as PropType<BoatCardProps["boatType"]>,
      required: false,
      default: null,
    },
    passengers: {
      type: Number as PropType<BoatCardProps["passengers"]>,
      required: false,
      default: null,
    },
    deckPlan: {
      type: Object as PropType<BoatCardProps["deckPlan"]>,
      required: false,
      default: null,
    },
    url: {
      type: String as PropType<BoatCardProps["url"]>,
      required: false,
      default: null,
    },
  },
});
</script>
