<template>
  <div
    v-if="carbonCalculation"
    data-cy="carbon-calculation"
    class="carbon-calculation"
  >
    <div
      class="
        carbon-calculation__content
        u-text-align--left
        md:u-text-align--center
      "
    >
      <div>
        This trip generates
        <span class="carbon-calculation__sub-content u-font-weight--bold">
          {{ carbonCalculation }} kg of CO<sub>2</sub>-e per person per
          day.</span
        >
        <span @click="openModal">
          <Icon
            name="info-circle-outline"
            class="icon--size-1-5 u-cursor--pointer"
            data-cy="carbon-calculation__info-icon"
          />
        </span>
      </div>
      <div>
        We offset these emissions on your behalf. But we know that is not
        enough, so we also have a
        <Link :href="$link.create('/purpose/climate')" target="_blank"
          >carbon reduction target.</Link
        >
      </div>
    </div>
    <Modal
      class="default"
      :show-modal="showDefaultModal"
      description="Carbon calculation modal"
      @close="closeModal"
    >
      <template slot="heading">
        {{ modalTitle }}
      </template>
      <template slot="content">
        <p>
          As a rough guide, 100 kg CO<sub>2</sub>-e is about the same amount of
          greenhouse gas emitted from:
        </p>
        <ul>
          <li>A gasoline-powered car driving about 399 km (248 mi)</li>
          <li>Charging a smartphone 12,164 times or</li>
          <li>
            Burning 4.1 cylinders of propane gas cylinders for a home barbecue
          </li>
        </ul>
        <p>
          Our footprint does not include your flights to and from the
          destination.
        </p>
      </template>
      <template slot="footer">
        <Button class="button button--primary" :onClick="closeModal">
          Close</Button
        >
      </template>
    </Modal>
  </div>
  <CarbonCalculationEmptyState v-else />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import Modal from "atlas/src/components/Modal/Modal.vue";
import Button from "atlas/src/components/Button/Button.vue";
import Link from "../Link/Link.vue";
import CarbonCalculationEmptyState from "./CarbonCalculationEmptyState.vue";
import { CarbonCalculationProps } from "./Props";

export default Vue.extend({
  name: "CarbonCalculation",
  components: {
    Icon,
    Link,
    Modal,
    Button,
    CarbonCalculationEmptyState,
  },
  props: {
    carbonCalculation: {
      type: Number as PropType<CarbonCalculationProps["carbonCalculation"]>,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      modalTitle: "Understanding this trip's carbon footprint",
      showDefaultModal: false,
    };
  },
  methods: {
    openModal() {
      this.showDefaultModal = true;
    },
    closeModal() {
      this.showDefaultModal = false;
    },
  },
});
</script>
<style lang="scss">
@import "./carbon-calculation";
</style>
