<template>
  <Card
    data-cy="content-panel-item"
    class="u-height--full"
    :class="computedClasses"
    :image="image"
    :href="computedHref"
  >
    <template slot="heading">
      {{ heading }}
    </template>
    <template v-if="!isCompact" slot="content">
      <div
        data-cy="content-panel-item--content"
        class="
          u-display--flex
          u-flex-direction--column
          u-justify-content--space-between
          u-height--full
        "
      >
        <JsonRichText v-if="description?.json" :json="description?.json" />
        <Button
          v-if="shouldRenderButton"
          :href="computedCTAUrl"
          class="button--secondary u-margin-top--1"
          >{{ ctaTitle }}</Button
        >
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Card from "atlas/src/components/Card/Card.vue";
import Button from "atlas/src/components/Button/Button.vue";
import { ContentPanelItemProps } from "./Props";
import { createLink } from "~/lib/utils/link/createLink";
import { ContentPanelCardDisplayType } from "~/lib/types/Contentstack/GlobalFields/ContentPanel";
import JsonRichText from "~/components/JsonRichText/JsonRichText.vue";

export default Vue.extend({
  name: "ContentPanelItem",
  components: {
    Card,
    JsonRichText,
    Button,
  },
  props: {
    heading: {
      type: String as PropType<ContentPanelItemProps["heading"]>,
      required: false,
      default: undefined,
    },
    description: {
      type: Object as PropType<ContentPanelItemProps["description"]>,
      required: false,
      default: undefined,
    },
    image: {
      type: Object as PropType<ContentPanelItemProps["image"]>,
      required: false,
      default: undefined,
    },
    ctaTitle: {
      type: String as PropType<ContentPanelItemProps["ctaTitle"]>,
      required: false,
      default: undefined,
    },
    url: {
      type: String as PropType<ContentPanelItemProps["url"]>,
      required: false,
      default: undefined,
    },
    displayType: {
      type: String as PropType<ContentPanelItemProps["diplayType"]>,
      required: true,
    },
  },
  computed: {
    computedClasses(): string {
      return this.displayType === ContentPanelCardDisplayType.Horizontal
        ? "card--horizontal"
        : "";
    },
    isCompact(): boolean {
      return this.displayType === ContentPanelCardDisplayType.VerticalCompact;
    },
    computedHref(): string | undefined {
      // If isCompact, item will rended as a link even if ctaTitle is present, because CTA will not show when compact
      if (this.isCompact || !this.ctaTitle) {
        return this.url ? createLink(this.$i18n.locale)(this.url) : undefined;
      }
      return undefined;
    },
    computedCTAUrl(): string | undefined {
      return this.url ? createLink(this.$i18n.locale)(this.url) : undefined;
    },
    shouldRenderButton(): boolean {
      return !!(this.ctaTitle && this.url);
    },
  },
});
</script>

<style lang="scss">
@import "./content-panel";
</style>
