<template>
  <AccordionItem>
    <b slot="title">Overview</b>
    <div slot="content">
      <div v-if="classification" data-cy="boat-details__classification">
        <span class="u-font-weight--semi-bold">Classification:</span>
        {{ classification }}
      </div>
      <div v-if="model" data-cy="boat-details__model">
        <span class="u-font-weight--semi-bold">Model:</span>
        {{ model }}
      </div>

      <div v-if="totalPassengers" data-cy="boat-details__total-passengers">
        <span class="u-font-weight--semi-bold"
          >Total number of passengers:</span
        >
        {{ totalPassengers }}
      </div>
      <div v-if="totalCrew" data-cy="boat-details__total-crew">
        <span class="u-font-weight--semi-bold">Total number of crew:</span>
        {{ totalCrew }}
      </div>
      <div v-if="passengerCrewRatio" data-cy="boat-details__model">
        <span class="u-font-weight--semi-bold">Ration of passenger crew:</span>
        {{ passengerCrewRatio }}
      </div>
      <div v-if="guide" data-cy="boat-details__guide">
        <span class="u-font-weight--semi-bold">Guide:</span> {{ guide }}
      </div>
      <div v-if="regions" data-cy="boat-details__region">
        <span class="u-font-weight--semi-bold">Regions:</span> {{ regions }}
      </div>
    </div>
  </AccordionItem>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import AccordionItem from "atlas/src/components/Accordion/AccordionItem.vue";

import { BoatDetailsProps } from "./Props";

export default Vue.extend({
  name: "BoatDetails",
  components: { AccordionItem },
  props: {
    classification: {
      type: String as PropType<BoatDetailsProps["classification"]>,
      required: false,
      default: null,
    },
    model: {
      type: String as PropType<BoatDetailsProps["model"]>,
      required: false,
      default: null,
    },
    totalPassengers: {
      type: Number as PropType<BoatDetailsProps["totalPassengers"]>,
      required: false,
      default: null,
    },
    totalCrew: {
      type: Number as PropType<BoatDetailsProps["totalCrew"]>,
      required: false,
      default: null,
    },
    passengerCrewRatio: {
      type: String as PropType<BoatDetailsProps["passengerCrewRatio"]>,
      required: false,
      default: null,
    },
    guide: {
      type: String as PropType<BoatDetailsProps["guide"]>,
      required: false,
      default: null,
    },
    regions: {
      type: String as PropType<BoatDetailsProps["regions"]>,
      required: false,
      default: null,
    },
  },
});
</script>
