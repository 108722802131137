import * as Yup from "yup";
import { isValidEmail } from "~/lib/utils/isValidEmail";
import { isValidPhoneNumber } from "~/lib/utils/isValidPhoneNumber";

export const ContactUsFormValidator = Yup.object().shape({
  isTravelAgent: Yup.boolean().required(
    "Please select if you're a customer or travel agent"
  ),
  firstName: Yup.string()
    .trim("Please enter your first name")
    .max(40, "First name must be under 40 characters")
    .required("Please enter your first name"),
  lastName: Yup.string()
    .trim("Please enter your last name")
    .max(40, "Last name must be under 40 characters")
    .required("Please enter your last name"),
  countryOfResidence: Yup.string().required(
    "Please enter your country of residence"
  ),
  email: Yup.string()
    .trim("Please enter your email")
    .max(320, "Email must be under 320 characters")
    .required("Please enter your email")
    .test(
      "Validate Email",
      "Email must be in format 'example@mail.com'",
      (value) => {
        if (!value) return true;
        return isValidEmail(value);
      }
    ),
  storeEmail: Yup.string()
    .when("isTravelAgent", ([isTravelAgent], schema) => {
      return isTravelAgent === true
        ? schema.required("Please enter your store email")
        : schema.notRequired();
    })
    .max(320, "Email must be under 320 characters")
    .test(
      "Validate Email",
      "Email must be in format 'example@mail.com'",
      (value) => {
        if (!value) return true;
        return isValidEmail(value);
      }
    ),
  contactMethod: Yup.string().required("Please choose a contact method"),
  countryCode: Yup.string().when("contactMethod", ([contactMethod], schema) => {
    return contactMethod === "Phone"
      ? schema.required("Please enter your country code")
      : schema.notRequired();
  }),
  phoneNumber: Yup.string()
    .when("contactMethod", ([contactMethod], schema) => {
      return contactMethod === "Phone"
        ? schema
            .trim("Please enter your phone number")
            .required("Please enter your phone number")
        : schema.notRequired();
    })
    .test(
      "Validate Phone Number",
      "Number must contain digits and can include '-', '( )', '.' and spaces. Check length.",
      (value) => {
        if (!value) return true;
        return isValidPhoneNumber(value, false);
      }
    ),
  hasExistingBooking: Yup.boolean().required(
    "Please select if this is about an existing booking or not"
  ),
  bookingReference: Yup.string().when(
    "hasExistingBooking",
    ([hasExistingBooking], schema) => {
      return hasExistingBooking === true
        ? schema
            .matches(
              /^[0-9]{5,10}$/,
              "Please enter your correct booking number"
            )
            .trim("Please enter your booking number")
            .required("Please enter your booking number")
        : schema.notRequired();
    }
  ),
  enquiryType: Yup.string().when(
    "hasExistingBooking",
    ([hasExistingBooking], schema) => {
      return hasExistingBooking === false
        ? schema.required("Please choose your enquiry type")
        : schema.notRequired();
    }
  ),
  message: Yup.string()
    .trim("Please write your question")
    .required("Please write your question"),
  subscribed: Yup.string().notRequired(),
  privacyAgreed: Yup.boolean()
    .required("Please agree to the privacy policy")
    .oneOf([true], "Please agree to the privacy policy"),
  recaptchaToken: Yup.string().required(
    "Please complete reCAPTCHA so we know you’re not a robot"
  ),
});
