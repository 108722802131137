<template>
  <AtlasTile
    v-if="action && action.href"
    :title="displayName"
    :buttonText="action.title"
    :link="localisedLink"
    class="tile--large"
  >
    <div v-if="htmlDescription" v-html="htmlDescription" />
  </AtlasTile>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import AtlasTile from "atlas/src/components/Tile/Tile.vue";
import { jsonToHtml } from "@contentstack/json-rte-serializer";
import { CallToActionTileProps } from "./Props";
import { prefixLocaleCodeToRelativePaths } from "~~/lib/mixins/prefixLocaleCodeToRelativePaths";

export default Vue.extend({
  name: "CallToActionTile",
  components: {
    AtlasTile,
  },
  mixins: [prefixLocaleCodeToRelativePaths],
  props: {
    displayName: {
      type: String as PropType<CallToActionTileProps["displayName"]>,
      required: false,
      default: undefined,
    },
    description: {
      type: Object as PropType<CallToActionTileProps["description"]>,
      required: false,
      default: undefined,
    },
    action: {
      type: Object as PropType<CallToActionTileProps["action"]>,
      required: true,
    },
  },
  computed: {
    htmlDescription() {
      if (this.description?.json) {
        return jsonToHtml(
          this.prefixLocaleCodeToRelativePaths(this.description.json)
        );
      }
      return "";
    },
    localisedLink() {
      if (this.action && this.action.href) {
        return this.$link.create(this.action.href);
      }
      return undefined;
    },
  },
});
</script>
