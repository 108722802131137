<template>
  <BlogCarousel v-if="blogPosts" data-cy="blog-panel" :blogPosts="blogPosts" />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import BlogCarousel from "../BlogCarousel/BlogCarousel.vue";
import { BlogCarouselProps } from "../BlogCarousel/Props";

export default Vue.extend({
  name: "BlogPanel",
  components: {
    BlogCarousel,
  },
  props: {
    blogPosts: {
      type: Array as PropType<BlogCarouselProps["blogPosts"]>,
      required: false,
      default() {
        return [];
      },
    },
  },
});
</script>
