<template>
  <div data-cy="content-panel" class="content-panel">
    <CarouselResponsive
      v-if="shouldDisplayAsCarousel"
      description="Responsive Carousel"
      :items-xs="1"
      :items-sm="deviceColumnConfig.tablet"
      :items-md="deviceColumnConfig.desktop"
      :items-lg="deviceColumnConfig.desktop"
      :items-xl="deviceColumnConfig.desktop"
    >
      <CarouselResponsiveItem v-for="(item, index) in content" :key="index">
        <ContentPanelItem v-bind="{ ...item, displayType }" />
      </CarouselResponsiveItem>
    </CarouselResponsive>

    <ContentPanelGrid
      v-else
      :columnsTablet="deviceColumnConfig.tablet"
      :columnsDesktop="deviceColumnConfig.desktop"
    >
      <ContentPanelItem
        v-for="(item, index) in content"
        v-bind="{ ...item, displayType }"
        :key="index"
      />
    </ContentPanelGrid>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import WindowSize from "atlas/src/mixins/WindowSize/WindowSize.js";
import CarouselResponsive from "atlas/src/components/CarouselResponsive/CarouselResponsive.vue";
import CarouselResponsiveItem from "atlas/src/components/CarouselResponsive/CarouselResponsiveItem.vue";
import { ContentPanelProps } from "./Props";
import ContentPanelItem from "./ContentPanelItem.vue";
import ContentPanelGrid from "./ContentPanelGrid.vue";
import {
  WindowSizeBreakpoint,
  WindowSizeType,
} from "~/lib/types/Atlas/WindowSize";

export default Vue.extend({
  name: "ContentPanel",
  components: {
    CarouselResponsive,
    CarouselResponsiveItem,
    ContentPanelItem,
    ContentPanelGrid,
  },
  mixins: [WindowSize],
  props: {
    displayType: {
      type: String as PropType<ContentPanelProps["displayType"]>,
      required: true,
    },
    content: {
      type: Array as PropType<ContentPanelProps["content"]>,
      required: true,
    },
    deviceColumnConfig: {
      type: Object as PropType<ContentPanelProps["deviceColumnConfig"]>,
      required: true,
    },
    breakpointsToApplyCarouselOn: {
      type: Array as PropType<
        ContentPanelProps["breakpointsToApplyCarouselOn"]
      >,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      shouldDisplayAsCarousel: false,
    };
  },
  mounted() {
    this.onWindowResize((windowSize: WindowSizeType) => {
      this.setShouldDisplayAsCarousel(windowSize);
    });
  },
  methods: {
    setShouldDisplayAsCarousel(windowSize: WindowSizeType) {
      this.shouldDisplayAsCarousel = this.breakpointsToApplyCarouselOn.includes(
        windowSize.breakpoint as WindowSizeBreakpoint
      );
    },
  },
});
</script>

<style lang="scss">
@import "./content-panel";
</style>
