<template>
  <Page v-bind="page">
    <PageHeadline
      v-if="title || description"
      :title="title"
      :description="description"
    />
    <ContactUsMethods
      v-bind="contactUsMethods"
      data-cy="contact-us-methods"
      @showContactUsForm="showContactUsForm"
    />
    <div ref="contactUsForm" :style="contactUsFormRefStyle" />
    <ContactUsForm v-if="contactUsForm" />
  </Page>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Cookies from "js-cookie";
import { ContactUsPageProps } from "./Props";
import Page from "~~/components/Page/Page.vue";
import PageHeadline from "~~/components/PageHeadline/PageHeadline.vue";
import ContactUsMethods from "~/components/ContactUsMethods/ContactUsMethods.vue";
import ContactUsForm from "~/components/ContactUsForm/ContactUsForm.vue";
import CookieKey from "~~/lib/types/CookieKey";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";

const logger = loggerFactory({
  tags: [logTags.Layer.Page, logTags.Page.ContactUsPage],
});

export default Vue.extend({
  name: "ContactUsPage",
  components: { Page, PageHeadline, ContactUsMethods, ContactUsForm },
  props: {
    page: {
      type: Object as PropType<ContactUsPageProps["page"]>,
      required: true,
    },
  },
  data() {
    return {
      title: "How can we help?",
      description: "We're here for you 24 hours 7 days a week",
      contactUsMethods: null,
      contactUsForm: false,
      headerBarOffset: 0 as number | undefined,
    };
  },
  computed: {
    contactUsFormRefStyle() {
      return `position: absolute; margin-top: -${this.headerBarOffset}px;`;
    },
  },
  async mounted() {
    const iso = Cookies.get(CookieKey.OriginCountry);
    if (iso) {
      await this.fetchContactUsMethods(iso);
    }
  },
  methods: {
    async fetchContactUsMethods(iso: string) {
      try {
        const currentLocale = this.$i18n.locale;
        this.contactUsMethods = await $fetch(
          "/api/nuxt/contact-us/contact-us-methods",
          {
            params: { iso: iso.toLowerCase(), locale: currentLocale },
          }
        );
      } catch (error) {
        logger.error("Error fetching contact us methods", { error });
      }
    },
    showContactUsForm() {
      this.contactUsForm = true;

      const headerBar = document.getElementById("header-bar");
      this.headerBarOffset = headerBar?.offsetHeight;

      this.$nextTick(() => {
        (this.$refs.contactUsForm as Element).scrollIntoView({
          behavior: "smooth",
        });
      });
    },
  },
});
</script>
