<template>
  <Card
    :image="image"
    data-cy="category-card"
    class="u-height--full"
    :href="$link.create(link)"
  >
    <p
      slot="heading"
      data-cy="category-card__title"
      class="headline--5 u-margin-top--0 u-margin-bottom--0"
    >
      {{ title }}
    </p>
  </Card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Card from "atlas/src/components/Card/Card.vue";
import { CategoryCardProps } from "./Props";

export default Vue.extend({
  name: "CategoryCard",
  components: {
    Card,
  },
  props: {
    title: {
      type: String as PropType<CategoryCardProps["title"]>,
      required: true,
    },
    image: {
      type: Object as PropType<CategoryCardProps["image"]>,
      required: false,
      default: undefined,
    },
    link: {
      type: String as PropType<CategoryCardProps["link"]>,
      required: true,
    },
  },
});
</script>
