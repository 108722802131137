<template>
  <div data-cy="call-to-action-panel">
    <CallToActionTile
      :displayName="displayName"
      :description="description"
      :action="action"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { CallToActionPanelProps } from "./Props";
import CallToActionTile from "~/components/CallToActionTile/CallToActionTile.vue";

export default Vue.extend({
  name: "CallToActionPanel",
  components: {
    CallToActionTile,
  },
  props: {
    displayName: {
      type: String as PropType<CallToActionPanelProps["displayName"]>,
      required: false,
      default: undefined,
    },
    description: {
      type: Object as PropType<CallToActionPanelProps["description"]>,
      required: false,
      default: undefined,
    },
    action: {
      type: Object as PropType<CallToActionPanelProps["action"]>,
      required: true,
    },
  },
});
</script>
