import dayjs from "dayjs";
import {
  ContactUsFormFields,
  FormDataProps,
  FormstackDataProps,
} from "~/components/ContactUsForm/Props";
import { getCountryByCode } from "~/lib/utils/locale/getCountryByCode";
import { countries } from "~/config/countries";
import { enquiryTypes } from "~/config/contactUsFormData";

export const ContactUsFormMapper = (
  formstackIds: ContactUsFormFields,
  currencyCode: string,
  salesRegion: string,
  formData: FormDataProps,
  salesforceContactID: string,
  salesforce: boolean
): FormstackDataProps => {
  let phoneCode = "";
  if (formData.countryCode) {
    phoneCode = countries.filter(
      (country) => country.iso2Code === formData.countryCode.toLowerCase()
    )[0].phoneCode;
  }
  const marketingRegion = getCountryByCode(
    formData.countryOfResidence
  )?.marketingRegion?.toString();

  // Email template
  let subject = `Regarding ${formData.enquiryType.toLowerCase()}`;
  let countryName = formData.countryOfResidence;
  let storeEmail = "N/A";
  let userType = "Customer";
  let enquiryType = `Enquiry type: ${formData.enquiryType}`;

  if (formData.hasExistingBooking) {
    subject = "Regarding existing booking";
    enquiryType = `Booking reference: ${formData.bookingReference}`;
  }

  if (formData.isTravelAgent) {
    userType = "Travel agent";
    storeEmail = formData.storeEmail;
  }

  if (formData.countryOfResidence) {
    countryName = countries.filter(
      (country) =>
        country.iso2Code ===
        (formData.countryOfResidence as string).toLowerCase()
    )[0].name;
  }

  return {
    [`field_${formstackIds?.is_travel_agent}`]: formData.isTravelAgent
      ? "Industry"
      : "Direct",
    [`field_${formstackIds?.first_name}`]: formData.firstName,
    [`field_${formstackIds?.last_name}`]: formData.lastName,
    [`field_${formstackIds?.store_email}`]: formData.isTravelAgent
      ? formData.storeEmail
      : "",
    [`field_${formstackIds?.country_of_residence}`]:
      formData.countryOfResidence,
    [`field_${formstackIds?.contact_method}`]: formData.contactMethod,
    [`field_${formstackIds?.email}`]: formData.email,
    [`field_${formstackIds?.country_code}`]: phoneCode,
    [`field_${formstackIds?.phone_number}`]: formData.phoneNumber,
    [`field_${formstackIds?.booking_reference}`]: formData.hasExistingBooking
      ? formData.bookingReference
      : "",
    [`field_${formstackIds?.enquiry_type}`]: formData.hasExistingBooking
      ? enquiryTypes.existingBooking
      : formData.enquiryType,
    [`field_${formstackIds?.message}`]: formData.message,
    [`field_${formstackIds?.subscribed}`]: formData.subscribed
      ? "True"
      : "False",
    [`field_${formstackIds?.privacy_agreed}`]: formData.privacyAgreed
      ? "true"
      : "false",
    [`field_${formstackIds?.submitted_datetime}`]: dayjs
      .utc(new Date())
      .toISOString(),

    [`field_${formstackIds?.currency_code}`]: currencyCode.toLocaleUpperCase(),
    [`field_${formstackIds?.sales_region}`]: salesRegion,
    [`field_${formstackIds?.marketing_region}`]: marketingRegion || "",

    [`field_${formstackIds?.subject_email_template}`]: subject,
    [`field_${formstackIds?.country_name_email_template}`]: countryName,
    [`field_${formstackIds?.store_email_email_template}`]: storeEmail,
    [`field_${formstackIds?.enquiry_type_email_template}`]: enquiryType,
    [`field_${formstackIds?.user_type_email_template}`]: userType,

    [`field_${formstackIds?.isSalesforceEnquiry}`]: salesforce
      ? "True"
      : "False",
    [`field_${formstackIds?.salesforce_contact_id}`]: salesforceContactID,
  };
};
