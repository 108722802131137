<template>
  <AccordionItem v-if="facilities">
    <b slot="title">Facilities</b>
    <div slot="content">
      <div
        v-for="(facility, index) in facilities"
        :key="index"
        data-cy="boat-facilities"
      >
        {{ facility }}
      </div>
    </div>
  </AccordionItem>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import AccordionItem from "atlas/src/components/Accordion/AccordionItem.vue";

export default Vue.extend({
  name: "BoatFacilities",
  components: { AccordionItem },
  props: {
    facilities: {
      type: Array as PropType<string[]>,
      required: false,
      default: null,
    },
  },
});
</script>
