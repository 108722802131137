<template>
  <CarouselResponsive
    v-if="categories.length > 0"
    :items-xs="1"
    :items-sm="2"
    :items-md="3"
    :items-lg="4"
    :items-xl="4"
    data-cy="category-list"
    description="Responsive Categories Carousel"
  >
    <CarouselResponsiveItem
      v-for="(category, index) in categories"
      :key="index"
    >
      <CategoryCard v-bind="category" />
    </CarouselResponsiveItem>
  </CarouselResponsive>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import CarouselResponsive from "atlas/src/components/CarouselResponsive/CarouselResponsive.vue";
import CarouselResponsiveItem from "atlas/src/components/CarouselResponsive/CarouselResponsiveItem.vue";
import { CategoryCardProps } from "~/components/CategoryCard/Props";
import CategoryCard from "~/components/CategoryCard/CategoryCard.vue";

export default Vue.extend({
  name: "CategoryList",
  components: {
    CategoryCard,
    CarouselResponsive,
    CarouselResponsiveItem,
  },
  props: {
    categories: {
      type: Array as PropType<CategoryCardProps[]>,
      required: true,
    },
  },
});
</script>
