<template>
  <div
    class="
      l-container
      u-padding-top--2
      sm:u-padding-top--6
      u-padding-bottom--1
      sm:u-padding-bottom--3
    "
  >
    <div class="l-grid">
      <div
        v-for="(contactUsMethod, index) in contactUsMethodsStaticData"
        :key="index"
        :class="
          'l-grid__cell l-grid__cell--4-col u-margin-bottom--0 sm:u-margin-bottom--1 ' +
          (!tileEnabled(contactUsMethod.name) && 'xs:u-display--none')
        "
      >
        <Tile
          v-if="tileEnabled(contactUsMethod.name)"
          :key="index"
          :title="contactUsMethod.title"
          :icon="contactUsMethod.icon"
          class="tile--horizontal tile--horizontal-start"
          :class="tileBackgroundColor(contactUsMethod.name)"
          :data-cy="contactUsMethod.dataCyMethod"
          :button-text="contactUsMethod.buttonText"
          v-on="
            contactUsMethod.buttonText && {
              click: () => {
                handleClickEvent(contactUsMethod.name);
              },
            }
          "
        >
          <p v-if="contactUsMethod.name === 'faq'">
            Check out our
            <AppLink
              :to="$link.create('/contact-us/frequently-asked-questions')"
            >
              frequently asked questions</AppLink
            >
            as we might already have the answer
          </p>
          <p v-if="contactUsMethod.name === 'directions'">
            Call the local number in your Essential Trip Information
          </p>
          <template
            v-if="
              contactUsMethod.name === 'callUs' &&
              (salesCountryPhoneNumber ||
                directPhoneNumber ||
                salesRegionPhoneNumber)
            "
          >
            <p>
              Call us on
              <AppLink
                :to="`tel:${
                  salesCountryPhoneNumber ||
                  directPhoneNumber ||
                  salesRegionPhoneNumber
                }`"
                :aria-label="`call us ${
                  salesCountryPhoneNumber ||
                  directPhoneNumber ||
                  salesRegionPhoneNumber
                }`"
              >
                {{
                  salesCountryPhoneNumber ||
                  directPhoneNumber ||
                  salesRegionPhoneNumber
                }}
              </AppLink>
            </p>
            <p v-if="tailorMadePhoneNumber">
              Tailor-Made
              <AppLink
                :to="`tel:${tailorMadePhoneNumber}`"
                :aria-label="`tailormade ${tailorMadePhoneNumber}`"
              >
                {{ tailorMadePhoneNumber }}
              </AppLink>
            </p>
            <div
              v-if="overseasPhoneNumber && salesRegionName"
              class="u-margin-bottom--0"
            >
              <p class="u-margin-bottom--0">
                <span class="u-font-weight--bold"
                  >Not in {{ salesRegionName }}?</span
                >
                <br />
                Call us on
                <AppLink
                  :to="`tel:${overseasPhoneNumber}`"
                  :aria-label="`overseas ${overseasPhoneNumber}`"
                >
                  {{ overseasPhoneNumber }}
                </AppLink>
              </p>
            </div>
          </template>
          <p v-if="contactUsMethod.name === 'emailUs'">
            Send us your question and we’ll reach out as soon as possible
          </p>
          <p v-if="contactUsMethod.name === 'chatToUs'">
            Message us on live chat
          </p>
          <p
            v-if="contactUsMethod.name === 'address'"
            class="u-white-space--pre-line"
          >
            <span>{{ address }}</span>
          </p>
        </Tile>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Tile from "atlas/src/components/Tile/Tile.vue";
import AppLink from "atlas/src/components/AppLink/AppLink.vue";
import { ContactUsMethodProps } from "./Props";

export default Vue.extend({
  name: "ContactUsMethods",
  components: {
    Tile,
    AppLink,
  },
  props: {
    salesCountryPhoneNumber: {
      type: String as PropType<ContactUsMethodProps["salesCountryPhoneNumber"]>,
      required: false,
      default: null,
    },
    salesRegionName: {
      type: String as PropType<ContactUsMethodProps["salesRegionName"]>,
      required: false,
      default: null,
    },
    directPhoneNumber: {
      type: String as PropType<ContactUsMethodProps["directPhoneNumber"]>,
      required: false,
      default: null,
    },
    overseasPhoneNumber: {
      type: String as PropType<ContactUsMethodProps["overseasPhoneNumber"]>,
      required: false,
      default: null,
    },
    tailorMadePhoneNumber: {
      type: String as PropType<ContactUsMethodProps["tailorMadePhoneNumber"]>,
      required: false,
      default: null,
    },
    address: {
      type: String as PropType<ContactUsMethodProps["address"]>,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      contactUsMethodsStaticData: [
        {
          name: "faq",
          title: "Browse our FAQs",
          icon: "quiz",
        },
        {
          name: "directions",
          title: "On your trip or on your way?",
          icon: "directions-walk",
        },
        {
          name: "callUs",
          title: "Call us",
          icon: "phone-iphone",
        },
        {
          name: "emailUs",
          title: "Email us",
          icon: "email-outline",
          buttonText: "Write my email",
        },
        {
          name: "chatToUs",
          title: "Chat to us",
          icon: "chat-outline",
          buttonText: "Live chat",
          dataCyMethod: "contact-us-method-chat",
        },
        {
          name: "address",
          title: "Our local address",
          icon: "location-on",
          dataCyMethod: "contact-us-method-address",
        },
      ],
      contactUsFormEnabled: false,
    };
  },
  computed: {
    salesRegionPhoneNumber(): string {
      return this.$store.getters["salesRegion/directPhoneNumber"];
    },
  },
  methods: {
    liveChatClick() {
      window?.embedded_svc && window?.embedded_svc?.initLightningOut();
    },
    showContactUsForm() {
      this.contactUsFormEnabled = true;
      this.$emit("showContactUsForm");
    },
    handleClickEvent(name: string) {
      if (name === "chatToUs") return this.liveChatClick();
      if (name === "emailUs") return this.showContactUsForm();
    },
    tileEnabled(name: string) {
      if (name === "address") {
        return this.address;
      }
      if (name === "chatToUs") {
        return this.$store.getters["liveChat/getLiveChatStatus"];
      }
      return true;
    },
    tileBackgroundColor(name: string) {
      return name === "emailUs" && this.contactUsFormEnabled
        ? "u-background-color--sand"
        : "";
    },
  },
});
</script>
