<template>
  <div>
    <h2 data-cy="boat-page__video-title">Onboard the {{ boatName }}</h2>
    <Video v-bind="video" data-cy="boat-page__video" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { BoatVideoProps } from "./Props";
import Video from "~/components/Video/Video.vue";

export default Vue.extend({
  name: "BoatPage",
  components: {
    Video,
  },
  props: {
    video: {
      type: Object as PropType<BoatVideoProps["video"]>,
      required: false,
      default: undefined,
    },
    boatName: {
      type: String as PropType<BoatVideoProps["boatName"]>,
      required: true,
      default: undefined,
    },
  },
});
</script>
