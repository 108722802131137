<template>
  <div v-if="boats && boats.length > 0">
    <BoatCard
      v-for="(boat, index) in boats"
      :key="index"
      :boatName="boat.boatName"
      :image="boat.image"
      :description="boat.description"
      :passengers="boat.passengers"
      :boatType="boat.boatType"
      :deckPlan="boat.deckPlan"
      :url="boat.url"
      class="u-margin-bottom--1-5"
    />
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import BoatCard from "../BoatCard/BoatCard.vue";
import { BoatListProps } from "./Props";
export default Vue.extend({
  name: "BoatList",
  components: {
    BoatCard,
  },
  props: {
    boats: {
      type: Array as PropType<BoatListProps["boat"]>,
      required: false,
      default: null,
    },
  },
});
</script>
