import Vue from "vue";
import TextField from "atlas/src/components/TextField/TextField.vue";
import TextArea from "atlas/src/components/TextArea/TextArea.vue";
import SelectField from "atlas/src/components/SelectField/SelectField.vue";
import Radio from "atlas/src/components/Radio/Radio.vue";
import RadioGroup from "atlas/src/components/Radio/RadioGroup.vue";
import Checkbox from "atlas/src/components/Checkbox/Checkbox.vue";
import Spinner from "atlas/src/components/Spinner/Spinner.vue";
import Alert from "atlas/src/components/Alert/Alert.vue";
import Button from "atlas/src/components/Button/Button.vue";
import Tile from "atlas/src/components/Tile/Tile.vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import * as Yup from "yup";
import AppLink from "atlas/src/components/AppLink/AppLink.vue";
import Honeypot from "../Honeypot/Honeypot.vue";
import { ContactUsFormMapper } from "./ContactUsFormMapper";
import { SubscriptionFormMapper } from "~/components/Subscription/SubscriptionFormMapper";
import { countries } from "~/config/countries";
import { contactUsForm, subscriptionForm } from "~/config/formstackForms";
import { Environment } from "~/.aws/lib/types";
import { getLocaleByCode } from "~/lib/utils/locale/getLocaleByCode";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";
import { enquiryTypes } from "~/config/contactUsFormData";
import { ContactUsFormValidator } from "~/components/ContactUsForm/ContactUsFromValidator";
import { fetchParser } from "~~/lib/utils/datetime/dateTimeReviver";
const logger = loggerFactory({
  tags: [logTags.Layer.Component, logTags.Feature.Webform]
});
export default Vue.extend({
  name: "ContactUsForm",
  components: {
    Alert,
    Button,
    Checkbox,
    Honeypot,
    Radio,
    RadioGroup,
    SelectField,
    Spinner,
    TextArea,
    TextField,
    Tile,
    AppLink,
    Icon
  },
  data() {
    return {
      submitted: false,
      sending: false,
      error: false,
      errorsData: {
        isTravelAgent: [],
        firstName: [],
        lastName: [],
        countryOfResidence: [],
        email: [],
        storeEmail: [],
        contactMethod: [],
        countryCode: [],
        phoneNumber: [],
        hasExistingBooking: [],
        bookingReference: [],
        enquiryType: [],
        message: [],
        subscribed: [],
        privacyAgreed: [],
        recaptchaToken: []
      },
      contactUsForm: {
        isTravelAgent: null,
        firstName: "",
        lastName: "",
        countryOfResidence: "",
        email: "",
        storeEmail: "",
        contactMethod: "",
        countryCode: "",
        phoneNumber: "",
        hasExistingBooking: null,
        bookingReference: "",
        enquiryType: "",
        message: "",
        subscribed: false,
        privacyAgreed: false,
        recaptchaToken: ""
      },
      contactUsFormstackIDs: contactUsForm === null || contactUsForm === void 0 ? void 0 : contactUsForm.uat,
      subscriptionFormstackIDs: subscriptionForm === null || subscriptionForm === void 0 ? void 0 : subscriptionForm.uat,
      currencyCode: "",
      salesRegion: "",
      schema: ContactUsFormValidator,
      salesforceContactID: "",
      isSalesforceEnquiry: false,
      headerBarOffset: 0
    };
  },
  computed: {
    countryCodeLabel() {
      return `Country code ${this.contactUsForm.contactMethod === "Phone" ? "*" : ""}`;
    },
    phoneNumberLabel() {
      return `Phone number ${this.contactUsForm.contactMethod === "Phone" ? "*" : ""}`;
    },
    computedCountries() {
      const countryList = [{
        id: "",
        value: ""
      }];
      countries.forEach(country => {
        countryList.push({
          id: country.iso2Code.toUpperCase(),
          value: country.name
        });
      });
      return countryList;
    },
    computedPhoneCodes() {
      const phoneCodes = [{
        id: "",
        value: ""
      }];
      countries.forEach(country => {
        phoneCodes.push({
          id: country.iso2Code.toUpperCase(),
          value: `${country.name} (${country.phoneCode})`
        });
      });
      return phoneCodes;
    },
    enquiryTypes() {
      return [{
        id: "",
        value: ""
      }, {
        id: enquiryTypes.newBooking,
        value: enquiryTypes.newBooking
      }, {
        id: enquiryTypes.tripInformation,
        value: enquiryTypes.tripInformation
      }, {
        id: enquiryTypes.generalEnquiry,
        value: enquiryTypes.generalEnquiry
      }, {
        id: enquiryTypes.tailorMade,
        value: enquiryTypes.tailorMade
      }];
    },
    scrollingRefStyle() {
      return `position: absolute; margin-top: -${this.headerBarOffset}px;`;
    }
  },
  created() {
    this.getFormstackIds();
    this.currencyCode = this.$route.params.currencyCode || this.$i18n.localeProperties.currencyCode;
    const locale = getLocaleByCode(this.$i18n.locale);
    this.salesRegion = locale !== null && locale !== void 0 && locale.enduranceRegion ? locale.enduranceRegion : locale.code;
  },
  methods: {
    async submitForm() {
      if (this.sending) return;
      await this.validateRecaptcha();
      try {
        const honeypot = this.$refs.honeypot;
        if (honeypot) {
          honeypot.validate();
        }
        Object.keys(this.errorsData).forEach(key => {
          this.errorsData[key] = [];
        });
        if (!this.contactUsForm.isTravelAgent) {
          this.contactUsForm.storeEmail = "";
        }
        await this.schema.validate(this.contactUsForm, {
          abortEarly: false
        });
        const checkDataGoesSalesforce = this.checkDataGoesSalesforce({
          enquiryType: this.contactUsForm.enquiryType,
          salesRegion: this.salesRegion,
          countryOfResidence: this.contactUsForm.countryOfResidence
        });
        this.isSalesforceEnquiry = checkDataGoesSalesforce;
        if (checkDataGoesSalesforce) {
          this.sending = true;
          const salesforceCreateResponse = await this.createContactOnSalesforce({
            firstName: this.contactUsForm.firstName,
            lastName: this.contactUsForm.lastName,
            email: this.contactUsForm.email
          });
          if (salesforceCreateResponse) {
            this.salesforceContactID = salesforceCreateResponse;
          }
        }
        if (checkDataGoesSalesforce && this.salesforceContactID || !checkDataGoesSalesforce) {
          const {
            contactUs,
            subscription
          } = this.mapFormData();
          this.sending = true;
          await this.sendContactUsFormData(contactUs);
          if (this.contactUsForm.subscribed) {
            await this.sendSubscriptionFormData(subscription);
          }
          await this.$recaptcha.reset();
        }
      } catch (errors) {
        if (errors instanceof Yup.ValidationError) {
          const errorRefs = [];
          errors.inner.forEach(error => {
            if (error.path) {
              errorRefs.push(error.path);
              this.errorsData[error.path] = [error.message];
            }
          });
          if (errorRefs.length > 0) {
            this.scrollTo(errorRefs[0]);
          }
        }
      }
    },
    mapFormData() {
      let subscriptionData = {};
      const contactUsData = ContactUsFormMapper(this.contactUsFormstackIDs, this.currencyCode, this.salesRegion, this.contactUsForm, this.salesforceContactID, this.isSalesforceEnquiry);
      if (this.contactUsForm.subscribed) {
        const formData = {
          firstName: this.contactUsForm.firstName,
          lastName: this.contactUsForm.lastName,
          email: this.contactUsForm.email,
          countryOfResidence: this.contactUsForm.countryOfResidence,
          sourceType: "IG Website",
          source: "Enquiry"
        };
        subscriptionData = SubscriptionFormMapper(this.subscriptionFormstackIDs, formData);
      }
      return {
        contactUs: contactUsData,
        subscription: subscriptionData
      };
    },
    async createContactOnSalesforce(contactUsDetails) {
      try {
        let salesforceContactID = "";
        const checkContactResponse = await this.checkContactExistOnSalesforce(contactUsDetails);
        const {
          contactID
        } = checkContactResponse;
        if (!contactID) {
          var _response$compositeRe;
          const response = await $fetch("/api/nuxt/salesforce/create-contact", {
            method: "POST",
            body: {
              firstName: contactUsDetails.firstName,
              lastName: contactUsDetails.lastName,
              email: contactUsDetails.email
            },
            parseResponse: fetchParser
          });
          salesforceContactID = response === null || response === void 0 ? void 0 : (_response$compositeRe = response.compositeResponse[1]) === null || _response$compositeRe === void 0 ? void 0 : _response$compositeRe.body.id;
        } else {
          salesforceContactID = contactID;
        }
        return salesforceContactID;
      } catch (error) {
        this.sending = false;
        this.error = true;
        logger.error("Error on contact create on SALESFORCE ", error);
        this.scrollTo("contactusFormErrorMessage");
      }
    },
    async checkContactExistOnSalesforce(contactUsDetails) {
      let response = {
        contactID: "",
        Name: ""
      };
      try {
        response = await $fetch("/api/nuxt/salesforce/existing-contact", {
          params: {
            firstName: contactUsDetails.firstName,
            lastName: contactUsDetails.lastName,
            email: contactUsDetails.email,
            cache: Date.now()
          },
          parseResponse: fetchParser
        });
      } catch (error) {
        this.sending = false;
        this.error = true;
        logger.error("Error on check contact exist on SALESFORCE ", error);
        this.scrollTo("contactusFormErrorMessage");
      }
      return response;
    },
    async sendContactUsFormData(formData) {
      try {
        var _this$contactUsFormst;
        const response = await $fetch("/api/nuxt/webform", {
          method: "POST",
          body: {
            formId: (_this$contactUsFormst = this.contactUsFormstackIDs) === null || _this$contactUsFormst === void 0 ? void 0 : _this$contactUsFormst.id,
            formData: JSON.stringify(formData)
          }
        });
        if (response === undefined) {
          this.error = true;
          this.scrollTo("contactusFormErrorMessage");
        } else {
          this.submitted = true;
          this.scrollTo("contactusFormSuccessMessage");
        }
        this.sending = false;
      } catch (error) {
        logger.error("Error submit contact us form data to FORMSTACK", error);
        this.error = true;
        this.sending = false;
        this.scrollTo("contactusFormErrorMessage");
      }
    },
    checkDataGoesSalesforce(data) {
      const notRequiredSalesRegion = ["be", "nl", "mt"];
      return data.enquiryType !== enquiryTypes.tailorMade && !notRequiredSalesRegion.includes(data.salesRegion) && !(data.salesRegion === "de" && data.countryOfResidence === "AT");
    },
    async sendSubscriptionFormData(formData) {
      try {
        var _this$subscriptionFor;
        await $fetch("/api/nuxt/webform", {
          method: "POST",
          body: {
            formId: (_this$subscriptionFor = this.subscriptionFormstackIDs) === null || _this$subscriptionFor === void 0 ? void 0 : _this$subscriptionFor.id,
            formData: JSON.stringify(formData)
          }
        });
      } catch (error) {
        logger.error("Error submit subscription form data to FORMSTACK ", error);
      }
    },
    scrollTo(ref) {
      const headerBar = document.getElementById("header-bar");
      this.headerBarOffset = headerBar === null || headerBar === void 0 ? void 0 : headerBar.offsetHeight;
      setTimeout(() => {
        if (this.$refs[ref]) {
          this.$refs[ref].scrollIntoView({
            behavior: "smooth"
          });
        }
      }, 100);
    },
    getFormstackIds() {
      var _this$$config, _this$$config$public, _this$$config2, _this$$config2$public;
      if (((_this$$config = this.$config) === null || _this$$config === void 0 ? void 0 : (_this$$config$public = _this$$config.public) === null || _this$$config$public === void 0 ? void 0 : _this$$config$public.environment) === Environment.PRD || ((_this$$config2 = this.$config) === null || _this$$config2 === void 0 ? void 0 : (_this$$config2$public = _this$$config2.public) === null || _this$$config2$public === void 0 ? void 0 : _this$$config2$public.environment) === Environment.STAGING) {
        this.contactUsFormstackIDs = contactUsForm === null || contactUsForm === void 0 ? void 0 : contactUsForm.prd;
        this.subscriptionFormstackIDs = subscriptionForm === null || subscriptionForm === void 0 ? void 0 : subscriptionForm.prd;
      }
    },
    validate(field) {
      try {
        if (field === "isTravelAgent") {
          this.errorsData.storeEmail = [];
        }
        if (field === "contactMethod") {
          this.errorsData.phoneNumber = [];
          this.errorsData.countryCode = [];
        }
        if (field === "hasExistingBooking") {
          this.errorsData.bookingReference = [];
          this.errorsData.enquiryType = [];
        }
        this.schema.validateSyncAt(field, this.contactUsForm);
        this.errorsData[field] = [];
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          this.errorsData[field] = [error.message];
        }
      }
    },
    getRadioGroupTitleClass(errors) {
      return errors && errors.length ? "contactus-form__radio-group--error" : "contactus-form__radio-group";
    },
    getRadioGroupClass(errors) {
      return errors.length > 0 ? "is-invalid u-margin-bottom--0" : "u-margin-bottom--0";
    },
    async validateRecaptcha() {
      try {
        const token = await this.$recaptcha.getResponse();
        this.contactUsForm.recaptchaToken = token;
      } catch (error) {
        this.contactUsForm.recaptchaToken = "";
      }
    },
    handleRecaptchaError() {
      this.contactUsForm.recaptchaToken = "";
      this.validate("recaptchaToken");
    },
    handleRecaptchaSuccess(token) {
      this.contactUsForm.recaptchaToken = token;
      this.validate("recaptchaToken");
    },
    handleRecaptchaExpired() {
      this.contactUsForm.recaptchaToken = "";
      this.validate("recaptchaToken");
    }
  }
});