<template>
  <div
    data-cy="content-panel-grid"
    class="content-panel-grid"
    :class="computedClasses"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { ContentPanelGridProps } from "./Props";

export default Vue.extend({
  name: "ContentPanelContent",
  props: {
    columnsDesktop: {
      type: Number as PropType<ContentPanelGridProps["columnsDesktop"]>,
      required: true,
    },
    columnsTablet: {
      type: Number as PropType<ContentPanelGridProps["columnsTablet"]>,
      required: true,
    },
  },
  computed: {
    computedClasses() {
      return `content-panel-grid--tablet-${this.columnsTablet}-col content-panel-grid--desktop-${this.columnsDesktop}-col`;
    },
  },
});
</script>

<style lang="scss">
@import "./content-panel";
</style>
