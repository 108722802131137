<template>
  <div v-if="isEmptyBoatFeatures">
    <h2>Features</h2>
    <Accordion description="Boat Features" data-cy="boat-features">
      <div slot="content">
        <BoatDetails
          v-if="boatDetails"
          v-bind="boatDetails"
          data-cy="boat-feature__details"
        />
        <BoatFacilities
          v-if="facilities"
          :facilities="facilities"
          data-cy="boat-feature__facility"
        />
        <BoatEquipment
          v-if="equipments"
          :equipments="equipments"
          data-cy="boat-feature__equipments"
        />
        <BoatAccessibility
          v-if="accessibilities"
          data-cy="boat-feature__accessibility"
          :accessibilities="accessibilities"
        />
        <BoatTechnicalSpecifications
          v-if="boatTechnicalSpecifications"
          data-cy="boat-feature__technical-specifications"
          v-bind="boatTechnicalSpecifications"
        />
      </div>
    </Accordion>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import Accordion from "atlas/src/components/Accordion/Accordion.vue";
import { BoatFeaturesProps } from "./Props";
import BoatDetails from "~~/components/BoatDetails/BoatDetails.vue";
import BoatFacilities from "~~/components/BoatFacilities/BoatFacilities.vue";
import BoatEquipment from "~~/components/BoatEquipment/BoatEquipment.vue";
import BoatAccessibility from "~~/components/BoatAccessibility/BoatAccessibility.vue";
import BoatTechnicalSpecifications from "~~/components/BoatTechnicalSpecifications/BoatTechnicalSpecifications.vue";
export default Vue.extend({
  name: "BoatFeatures",
  components: {
    Accordion,
    BoatDetails,
    BoatFacilities,
    BoatEquipment,
    BoatAccessibility,
    BoatTechnicalSpecifications,
  },
  props: {
    boatDetails: {
      type: Object as PropType<BoatFeaturesProps["boatDetails"]>,
      required: false,
      default: null,
    },
    facilities: {
      type: Array as PropType<BoatFeaturesProps["facilities"]>,
      required: false,
      default: null,
    },
    equipments: {
      type: Array as PropType<BoatFeaturesProps["equipments"]>,
      required: false,
      default: null,
    },
    accessibilities: {
      type: Array as PropType<BoatFeaturesProps["accessibilities"]>,
      required: false,
      default: null,
    },
    boatTechnicalSpecifications: {
      type: Object as PropType<
        BoatFeaturesProps["boatTechnicalSpecifications"]
      >,
      required: false,
      default: null,
    },
  },
  computed: {
    isEmptyBoatFeatures() {
      return (
        this.boatDetails ||
        this.facilities ||
        this.equipments ||
        this.accessibilities ||
        this.boatTechnicalSpecifications
      );
    },
  },
});
</script>
