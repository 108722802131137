var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"l-container u-padding-top--2 sm:u-padding-top--6 u-padding-bottom--1 sm:u-padding-bottom--3"},[_c('div',{staticClass:"l-grid"},_vm._l((_vm.contactUsMethodsStaticData),function(contactUsMethod,index){return _c('div',{key:index,class:'l-grid__cell l-grid__cell--4-col u-margin-bottom--0 sm:u-margin-bottom--1 ' +
        (!_vm.tileEnabled(contactUsMethod.name) && 'xs:u-display--none')},[(_vm.tileEnabled(contactUsMethod.name))?_c('Tile',_vm._g({key:index,staticClass:"tile--horizontal tile--horizontal-start",class:_vm.tileBackgroundColor(contactUsMethod.name),attrs:{"title":contactUsMethod.title,"icon":contactUsMethod.icon,"data-cy":contactUsMethod.dataCyMethod,"button-text":contactUsMethod.buttonText}},
          contactUsMethod.buttonText && {
            click: () => {
              _vm.handleClickEvent(contactUsMethod.name);
            },
          }
        ),[(contactUsMethod.name === 'faq')?_c('p',[_vm._v("\n          Check out our\n          "),_c('AppLink',{attrs:{"to":_vm.$link.create('/contact-us/frequently-asked-questions')}},[_vm._v("\n            frequently asked questions")]),_vm._v("\n          as we might already have the answer\n        ")],1):_vm._e(),_vm._v(" "),(contactUsMethod.name === 'directions')?_c('p',[_vm._v("\n          Call the local number in your Essential Trip Information\n        ")]):_vm._e(),_vm._v(" "),(
            contactUsMethod.name === 'callUs' &&
            (_vm.salesCountryPhoneNumber ||
              _vm.directPhoneNumber ||
              _vm.salesRegionPhoneNumber)
          )?[_c('p',[_vm._v("\n            Call us on\n            "),_c('AppLink',{attrs:{"to":`tel:${
                _vm.salesCountryPhoneNumber ||
                _vm.directPhoneNumber ||
                _vm.salesRegionPhoneNumber
              }`,"aria-label":`call us ${
                _vm.salesCountryPhoneNumber ||
                _vm.directPhoneNumber ||
                _vm.salesRegionPhoneNumber
              }`}},[_vm._v("\n              "+_vm._s(_vm.salesCountryPhoneNumber ||
                _vm.directPhoneNumber ||
                _vm.salesRegionPhoneNumber)+"\n            ")])],1),_vm._v(" "),(_vm.tailorMadePhoneNumber)?_c('p',[_vm._v("\n            Tailor-Made\n            "),_c('AppLink',{attrs:{"to":`tel:${_vm.tailorMadePhoneNumber}`,"aria-label":`tailormade ${_vm.tailorMadePhoneNumber}`}},[_vm._v("\n              "+_vm._s(_vm.tailorMadePhoneNumber)+"\n            ")])],1):_vm._e(),_vm._v(" "),(_vm.overseasPhoneNumber && _vm.salesRegionName)?_c('div',{staticClass:"u-margin-bottom--0"},[_c('p',{staticClass:"u-margin-bottom--0"},[_c('span',{staticClass:"u-font-weight--bold"},[_vm._v("Not in "+_vm._s(_vm.salesRegionName)+"?")]),_vm._v(" "),_c('br'),_vm._v("\n              Call us on\n              "),_c('AppLink',{attrs:{"to":`tel:${_vm.overseasPhoneNumber}`,"aria-label":`overseas ${_vm.overseasPhoneNumber}`}},[_vm._v("\n                "+_vm._s(_vm.overseasPhoneNumber)+"\n              ")])],1)]):_vm._e()]:_vm._e(),_vm._v(" "),(contactUsMethod.name === 'emailUs')?_c('p',[_vm._v("\n          Send us your question and we’ll reach out as soon as possible\n        ")]):_vm._e(),_vm._v(" "),(contactUsMethod.name === 'chatToUs')?_c('p',[_vm._v("\n          Message us on live chat\n        ")]):_vm._e(),_vm._v(" "),(contactUsMethod.name === 'address')?_c('p',{staticClass:"u-white-space--pre-line"},[_c('span',[_vm._v(_vm._s(_vm.address))])]):_vm._e()],2):_vm._e()],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }