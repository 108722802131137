<template>
  <div v-if="roomOptions.length" data-cy="boat-cabin-list">
    <h2 data-cy="boat-cabin-list__title">Cabins</h2>
    <div class="xs:u-display--none sm:u-display--block">
      <div class="l-grid">
        <div
          v-for="(roomOption, index) of roomOptions"
          :key="index"
          class="l-grid__cell l-grid__cell--4-col"
        >
          <RoomOptionCard v-bind="roomOption" class="u-height--full" />
        </div>
      </div>
    </div>

    <CarouselResponsive
      description="Room options carousel"
      :items-xs="1"
      class="sm:u-display--none xs:u-display--block"
    >
      <CarouselResponsiveItem
        v-for="(roomOption, index) of roomOptions"
        :key="index"
      >
        <RoomOptionCard v-bind="roomOption" class="u-height--full" />
      </CarouselResponsiveItem>
    </CarouselResponsive>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import CarouselResponsive from "atlas/src/components/CarouselResponsive/CarouselResponsive.vue";
import CarouselResponsiveItem from "atlas/src/components/CarouselResponsive/CarouselResponsiveItem.vue";
import RoomOptionCard from "~~/components/RoomOptionCard/RoomOptionCard.vue";
import { RoomOptionCardProps } from "~~/components/RoomOptionCard/Props";

export default Vue.extend({
  name: "BoatCabinList",
  components: {
    RoomOptionCard,
    CarouselResponsive,
    CarouselResponsiveItem,
  },
  props: {
    roomOptions: {
      type: Array as PropType<RoomOptionCardProps[]>,
      required: false,
      default: undefined,
    },
  },
});
</script>
