<template>
  <Page v-bind="page">
    <ErrorBoundary
      v-if="introduction"
      class="
        l-container l-container--small
        u-margin-top--2
        u-margin-bottom--4
        sm:u-margin-top--6
      "
    >
      <Introduction
        v-bind="introduction"
        id="overview"
        data-anchor-text="Overview"
      />
    </ErrorBoundary>

    <ErrorBoundary>
      <SubNavigation />
    </ErrorBoundary>

    <div class="l-container">
      <ErrorBoundary
        v-if="categoryProducts?.trips && categoryProducts.trips.length > 0"
      >
        <TripList
          id="our-trips"
          type="utility"
          data-anchor-text="Our trips"
          v-bind="categoryProducts"
          class="u-margin-top--4 sm:u-margin-top--6"
        />
      </ErrorBoundary>
      <ErrorBoundary v-if="video">
        <Video
          id="video"
          data-anchor-text="Video"
          v-bind="video"
          class="u-margin-top--4 sm:u-margin-top--6"
        />
      </ErrorBoundary>
      <ErrorBoundary
        v-if="categoryHighlights && categoryHighlights?.length >= 1"
      >
        <HighlightList
          id="highlights"
          :highlights="categoryHighlights"
          data-anchor-text="Highlights"
          :highlightsTitle="`${categoryDisplayName} highlights`"
          class="u-margin-top--4 sm:u-margin-top--6"
        />
      </ErrorBoundary>
      <ErrorBoundary
        v-if="categoryUSPs && categoryUSPs?.length > 0"
        id="category-usp"
        class="u-margin-top--4 sm:u-margin-top--6"
        data-anchor-text="Why choose us"
        data-cy="category-usp-section"
      >
        <h2>Why choose us</h2>
        <UniqueSellingPointList :uniqueSellingPoints="categoryUSPs" />
      </ErrorBoundary>
      <ErrorBoundary v-if="promotedBoats?.boats?.boat?.length">
        <BoatSection
          id="boats"
          :title="promotedBoats.title"
          :description="promotedBoats.description"
          :boats="promotedBoats.boats.boat"
          class="u-margin-top--4 sm:u-margin-top--6"
          data-anchor-text="Boats"
        />
      </ErrorBoundary>
      <ErrorBoundary
        v-if="
          categoryProductsReviews?.reviewComments &&
          categoryProductsReviews?.reviewComments?.length > 0
        "
      >
        <Reviews
          data-anchor-text="Reviews"
          v-bind="categoryProductsReviewAggregate"
          :reviews="categoryProductsReviews"
          class="u-margin-top--4 sm:u-margin-top--6"
        >
          <h2 slot="title">Reviews</h2>
        </Reviews>
      </ErrorBoundary>
      <ErrorBoundary v-if="categoryFAQs && categoryFAQs.length > 0">
        <Faqs
          id="faqs"
          data-anchor-text="FAQs"
          faqTitle="Frequently asked questions"
          class="u-margin-top--4 sm:u-margin-top--6"
          :faqs="categoryFAQs"
          data-cy="category-page-faqs"
        />
      </ErrorBoundary>
      <ErrorBoundary
        v-if="
          categoryPromotedCategories && categoryPromotedCategories.length > 0
        "
      >
        <template
          v-for="(promotedCategory, index) in categoryPromotedCategories"
        >
          <CategorySection
            v-if="promotedCategory.categories.length > 0"
            v-bind="promotedCategory"
            :id="`promoted-category-${index + 1}`"
            :key="index"
            class="u-margin-top--4 sm:u-margin-top--6"
            :data-anchor-text="promotedCategory.displayName"
          />
        </template>
      </ErrorBoundary>
      <ErrorBoundary v-if="categoryBlogs">
        <BlogCarousel
          id="articles"
          data-anchor-text="Articles"
          v-bind="categoryBlogs"
          class="u-margin-top--4 sm:u-margin-top--6"
          carouselTitle="Get inspired on The Good Times"
        />
      </ErrorBoundary>
    </div>
  </Page>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { CategoryPageProps } from "./Props";
import ErrorBoundary from "~/components/ErrorBoundary/ErrorBoundary.vue";
import Page from "~/components/Page/Page.vue";
import Introduction from "~/components/Introduction/Introduction.vue";
import SubNavigation from "~/components/SubNavigation/SubNavigation.vue";
import HighlightList from "~/components/HighlightList/HighlightList.vue";
import TripList from "~/components/TripList/TripList.vue";
import Reviews from "~/components/Reviews/Reviews.vue";
import Video from "~/components/Video/Video.vue";
import Faqs from "~/components/Faqs/Faqs.vue";
import BlogCarousel from "~/components/BlogCarousel/BlogCarousel.vue";
import UniqueSellingPointList from "~/components/UniqueSellingPointList/UniqueSellingPointList.vue";
import BoatSection from "~/components/BoatSection/BoatSection.vue";
import CategorySection from "~/components/CategorySection/CategorySection.vue";

export default Vue.extend({
  name: "CategoryPage",
  components: {
    Page,
    Introduction,
    ErrorBoundary,
    SubNavigation,
    HighlightList,
    TripList,
    Reviews,
    Video,
    Faqs,
    BlogCarousel,
    UniqueSellingPointList,
    BoatSection,
    CategorySection,
  },
  props: {
    page: {
      type: Object as PropType<CategoryPageProps["page"]>,
      required: true,
    },
    introduction: {
      type: Object as PropType<CategoryPageProps["introduction"]>,
      required: false,
      default: undefined,
    },
    categoryProducts: {
      type: Object as PropType<CategoryPageProps["categoryProducts"]>,
      required: false,
      default: undefined,
    },
    categoryProductsReviewAggregate: {
      type: Object as PropType<
        CategoryPageProps["categoryProductsReviewAggregate"]
      >,
      required: false,
      default: undefined,
    },
    categoryProductsReviews: {
      type: Object as PropType<CategoryPageProps["categoryProductsReviews"]>,
      required: false,
      default: undefined,
    },
    video: {
      type: Object as PropType<CategoryPageProps["video"]>,
      required: false,
      default: undefined,
    },
    categoryDisplayName: {
      type: String as PropType<CategoryPageProps["categoryDisplayName"]>,
      required: true,
    },
    categoryHighlights: {
      type: Array as PropType<CategoryPageProps["categoryHighlights"]>,
      required: false,
      default: undefined,
    },
    promotedBoats: {
      type: Object as PropType<CategoryPageProps["promotedBoats"]>,
      required: false,
      default: undefined,
    },
    categoryFAQs: {
      type: Array as PropType<CategoryPageProps["categoryFAQs"]>,
      required: false,
      default: undefined,
    },
    categoryBlogs: {
      type: Object as PropType<CategoryPageProps["categoryBlogs"]>,
      required: false,
      default: undefined,
    },
    categoryUSPs: {
      type: Array as PropType<CategoryPageProps["categoryUSPs"]>,
      required: false,
      default: undefined,
    },
    categoryPromotedCategories: {
      type: Array as PropType<CategoryPageProps["categoryPromotedCategories"]>,
      required: false,
      default: undefined,
    },
  },
});
</script>
